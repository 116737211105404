<template>
  <div class="products-admin p-tb-sm header__sticky">
    <div v-if="isNewOrder" class="container flex__between">
      <button class="btn btn__red btn__sm" @click="emitToggleExiting">
        <font-awesome-icon icon="times" size="1x" class="mr-10" />Exit & Save
      </button>
      <button class="btn text__sm btn__green" @click="emitToggleCart">
        <font-awesome-icon icon="shopping-cart" size="lg" class="mr-10" />
        <span class="text__bold text__md text__white">${{ activeOrder.totalCost.toFixed(2) }}</span>
      </button>
    </div>
    <div v-if="isSearch" class="container flex__between">
      <span></span>
      <router-link to="/new-order/order-details" class="btn btn__sm btn__green">New Order</router-link>
    </div>
    <div v-if="isAdmin" class="container flex__between">
      <router-link to="/admin" class="link__none">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
      </router-link>
      <router-link to="/admin/products/new" class="btn btn__frost">
        New Product
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
    <div v-if="isPastOrder" class="container flex__between">
      <span></span>
      <router-link :to="`/past-order/${activeOrder._id}/order-confirm`" class="btn btn__frost">
        Confirm Order
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activeOrder"],
  data() {
    return {};
  },
  computed: {
    route() {
      return this.$route.fullPath;
    },
    isNewOrder() {
      return this.route.includes("/new-order");
    },
    isAdmin() {
      return this.route.includes("/admin");
    },
    isPastOrder() {
      return this.route.includes("/past-order");
    },
    isSearch() {
      return (
        !this.route.includes("/new-order") &&
        !this.route.includes("/admin") &&
        !this.route.includes("/past-order")
      );
    }
  },
  methods: {
    emitToggleCart() {
      this.$emit("toggleCart");
    },
    emitToggleExiting() {
      this.$emit("toggleExiting");
    }
  }
};
</script>

<style scoped lang="scss">
.products-admin {
  background-color: lighten($frost, 20%);
}
</style>