<template>
  <!-- <section class="container"> -->
  <ul class="products-filterlist">
    <li
      v-for="(value, name, index) in cleanObj"
      :key="index"
      class="flex__center products-filterlist--item"
    >
      <font-awesome-icon
        icon="times"
        size="xs"
        class="mr-10 icon"
        @click="eraseProductFilter(name)"
      />
      <span class="text__white text__sm">{{ cleanName(name) }}: {{ value }}</span>
    </li>
  </ul>
  <!-- </section> -->
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["filters", "categories"],
  computed: {
    cleanObj() {
      let filters = {};
      for (let key in this.filters) {
        if (
          this.filters[key] &&
          key !== "showFeatured" &&
          key !== "priceSort"
        ) {
          if (typeof this.filters[key] !== "object") {
            filters[key] = this.filters[key];
          } else if (this.filters[key].length >= 1) {
            filters[key] = this.filters[key].join(", ");
          }
        }
      }
      if (filters.categories) {
        filters.categories = this.categories
          .filter(item => this.filters.categories.includes(item._id))
          .map(item => item.name)
          .join(", ");
      }
      return filters;
    }
  },
  methods: {
    ...mapActions(["startEraseProductFilter"]),
    cleanName(name) {
      if (name === "name") return "Name";
      if (name === "priceMin") return "Min Price";
      if (name === "priceMax") return "Max Price";
     // if (name === "respaStatus") return "Respa";
      if (name === "categories") return "Categories";
      if (name === "status") return "Status";
    },
    eraseProductFilter(key) {
      this.startEraseProductFilter(key);
    }
  }
};
</script>

<style scoped lang="scss">
.products-filterlist {
  @include flex($just: flex-start, $wrap: wrap, $align: center);
  padding: 8px 0;
}
.products-filterlist--item {
  background-color: $peacock;
  border-radius: 15px;
  padding: 4px 7px;
  margin: 5px;
}
.icon {
  color: $powder;
  background-color: $dark-blue;
  border-radius: 50%;
  padding: 2px;
  width: 15px;
  height: 15px;
}
</style>