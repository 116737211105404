<template>
  <div class="modal" @click.self="emitToggleCart">
    <div class="products-details">
      <exit-button @toggleModal="emitToggleCart" class="pointer" />
      <h2 class="text__center product-details__span-2">Order Summary</h2>
      <simplebar class="cart--overflow" data-simplebar-auto-hide="false">
        <div class="product-details__grid">
          <p class="flex__between">
            <span class="text__bold mr-10">Recipient Type</span>
            <span class="text__right">{{ activeOrder.recipientType }}</span>
          </p>
          <p class="flex__between">
            <span class="text__bold mr-10">Recipient Name</span>
            <span class="text__overflow text__right">{{ activeOrder.recipientName }}</span>
          </p>
          <p class="flex__between text__bold">
            <span class>Total Cost</span>
            <span class="text__md text__green">${{ activeOrder.totalCost.toFixed(2) }}</span>
          </p>
          <p v-if="showRespaIssue" class="form-error--message text__sm">
            <font-awesome-icon icon="exclamation" size="1x" class="icon mr-10" />
            <span class="text__white">Potential Respa issue present</span>
          </p>
          <router-link
            :to="`/new-order/${activeOrder._id}/order-confirm`"
            class="btn btn__small btn__green flex__around"
          >
            Continue To Checkout
            <font-awesome-icon icon="arrow-right" size="sm" class="text__white ml-10" />
          </router-link>
          <button class="btn btn__red" @click="toggleExiting">
            <font-awesome-icon icon="times" size="lg" class="mr-10" />Exit & Save
          </button>
          <hr class="product-details__span-2" />
          <p
            v-if="!activeOrder.orderItems || activeOrder.orderItems.length <= 0"
            class="padding__sm bg-light-grey text__center"
          >Please add some products</p>
          <CartProducts :items="activeOrder.orderItems" :order="activeOrder" />
        </div>
      </simplebar>
    </div>
    <exit v-if="exiting">
      <ExitOrder @toggleExiting="toggleExiting" />
    </exit>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExitOrder from "./ExitOrder";
import CartProducts from "./CartProducts/Index";
export default {
  props: ["activeOrder"],
  components: {
    ExitOrder,
    CartProducts
  },
  data() {
    return {
      exiting: false
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
    totalCost() {
      if (!this.activeOrder) return 0;
      if (
        !this.activeOrder.orderItems ||
        this.activeOrder.orderItems.length <= 0
      )
        return 0;
      return this.activeOrder.orderItems
        .map(item => {
          const productCost = this.getProducts.filter(
            product => item.product === product._id
          )[0].approxValue;
          return item.quantity * productCost;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    showRespaIssue() {
      if (this.activeOrder.recipientType === "Internal Employee") return false;
      if (this.activeOrder.coRecipientName) {
        return this.totalCost > 50;
      } else {
        return this.totalCost > 25;
      }
    }
  },
  methods: {
    emitToggleCart() {
      this.$emit("toggleCart", false);
    },
    toggleExiting() {
      this.exiting = !this.exiting;
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  @include flex($just: center, $align: center);
}
.products-details {
  width: 80%;
  max-width: 400px;
  position: relative;
  padding: 20px;
  background-color: $frost;
}
.product-details__grid {
  @include grid($cols: 100%, $col-gap: 15px, $row-gap: 15px);
  hr {
    height: 3px;
    background-color: $orange;
    border: none;
  }
}
.cart--overflow {
  max-height: 300px;
}
@media (min-width: $sm) {
  .cart--overflow {
    max-height: 350px;
  }
}
</style>