<template>
  <div class="filters">
    <div class="flex__between">
      <h2>Filter and Sort</h2>
      <span class="breadcrumb" @click="reset">Reset</span>
    </div>
    <div class="flex__between p-tb-sm">
      <button class="btn btn__sm btn__red" @click="emitToggleFilters">Exit</button>
      <button class="btn btn__sm btn__frost" @click="submit">Submit</button>
    </div>
    <simplebar class="filters--overflow" data-simplebar-auto-hide="false">
      <div class="filters--form" @keypress.enter="submit">
        <label>
          Product Name
          <input type="text" placeholder="name" v-model="form.name" />
        </label>
        <div class="categories--select flex__between" @click="toggleCategories">
          <span>Categories</span>
          <span>
            <font-awesome-icon v-if="!categoriesToggled" icon="caret-down" size="sm" class="ml-10" />
            <font-awesome-icon v-else icon="caret-up" size="sm" class="ml-10" />
          </span>
        </div>
        <input v-if="categoriesToggled" type="text" v-model="search" placeholder="category search" />
        <simplebar
          v-if="categoriesToggled"
          class="major--overflow"
          data-simplebar-auto-hide="false"
        >
          <ul class="categories--list">
            <li v-for="category in filteredCategories" :key="category._id">
              <label>
                <input type="checkbox" :value="category._id" v-model="form.categories" />
                {{ category.name }}
              </label>
            </li>
          </ul>
        </simplebar>

        <label>
          Price Min
          <input type="number" v-mask="'######'" placeholder="0" v-model="form.priceMin" />
        </label>
        <label>
          Price Max
          <input type="number" v-mask="'######'" placeholder="100" v-model="form.priceMax" />
        </label>
        <!-- <label v-if="isAdmin" for="#">
          Respa Status
          <div class="checkbox-respa">
            <label>
              <input type="checkbox" value="any" v-model="form.respaStatus" />
              Any Order
            </label>
            <label>
              <input type="checkbox" value="co rec reqd" v-model="form.respaStatus" />
              Co-Recipient Reqd.
            </label>
            <label>
              <input type="checkbox" value="employee only" v-model="form.respaStatus" />
              Employee Only
            </label> -->
          <!-- </div>
        </label> -->
        <label for="#">
          Price Sort
          <div class="radio-sort">
            <label>
              <input
                type="radio"
                id="high-to-low"
                value="high-to-low"
                name="sort"
                v-model="form.priceSort"
              />
              High To Low
            </label>
            <label>
              <input
                type="radio"
                id="low-to-high"
                value="low-to-high"
                name="sort"
                v-model="form.priceSort"
              />
              Low to High
            </label>
          </div>
        </label>
        <label for="#" v-if="showStatus">
          <!-- don't show for new-order -->
          Product Status
          <div class="checkbox-status">
            <label>
              <input type="checkbox" value="live" v-model="form.status" />
              Live
            </label>
            <label>
              <input type="checkbox" value="coming-soon" v-model="form.status" />
              Coming Soon
            </label>
            <label>
              <input type="checkbox" value="test" v-model="form.status" />
              Test
            </label>
          </div>
        </label>
        <label for="#">
          Show Featured
          <div class="radio-featured">
            <label>
              <input
                type="radio"
                id="yes"
                :value="true"
                name="featured"
                v-model="form.showFeatured"
              />
              Show
            </label>
            <label>
              <input
                type="radio"
                id="no"
                :value="false"
                name="featured"
                v-model="form.showFeatured"
              />
              Hide
            </label>
          </div>
        </label>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["categories", "productFilters"],
  data() {
    return {
      categoriesToggled: false,
      search: "",
      form: {
        name: this.productFilters ? this.productFilters.name : null,
        priceMin: this.productFilters ? this.productFilters.priceMin : null,
        priceMax: this.productFilters ? this.productFilters.priceMax : null,
        //respaStatus: this.productFilters ? this.productFilters.respaStatus : [],
        categories: this.productFilters ? this.productFilters.categories : [],
        priceSort: this.productFilters
          ? this.productFilters.priceSort
          : "high-to-low",
        status: this.productFilters ? this.productFilters.status : [],
        showFeatured: this.productFilters
          ? this.productFilters.showFeatured
          : true
      }
    };
  },
  watch: {
    productFilters: {
      handler: function(newVal) {
        this.form = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  computed: {
    showStatus() {
      return !this.$route.fullPath.includes("/new-order");
    },
    isAdmin() {
      return this.$route.fullPath.includes("/admin");
    },
    filteredCategories() {
      if (!this.search) return this.categories;
      const cats = JSON.parse(JSON.stringify(this.categories));
      return cats.filter(cat => {
        return cat.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    ...mapActions(["startSetProductFilters", "startUnsetProductFilters"]),
    submit() {
      this.startSetProductFilters(this.form);
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    emitToggleFilters() {
      this.$emit("toggleFilters", false);
    },
    toggleCategories() {
      this.categoriesToggled = !this.categoriesToggled;
    },
    reset() {
      this.startUnsetProductFilters();
      this.$emit("resetPage");
      this.emitToggleFilters();
    },
    setCategories() {
      if (this.$route.query.category) {
        this.form.categories = [this.$route.query.category];
      }
    },
    setName() {
      if (this.$route.query.name) {
        const name = decodeURI(this.$route.query.name);
        this.form.name = name;
      }
    },
    // setRespaStatus() {
    //   const options = ["respa-borr", "respa-cb"];
    //   if (this.$route.query.respa) {
    //     if (options.includes(this.$route.query.respa)) {
    //       this.form.respaStatus = this.$route.query.respa;
    //     }
    //   }
    // },
    setStatus() {
      const options = ["live", "test", "coming-soon"];
      if (this.$route.query.status) {
        const status = this.$route.query.status;
        if (options.includes(status)) {
          this.form.status = [status];
        }
      }
    },
    setIsFeatured() {
      const options = ["yes", "no"];
      if (this.$route.query.featured) {
        const isFeatured = this.$route.query.featured;
        if (options.includes(isFeatured)) {
          this.form.isFeatured = isFeatured;
        }
      }
    }
  },
  mounted() {
    this.setCategories();
    this.setName();
    this.setRespaStatus();
    this.setStatus();
    this.setIsFeatured();
    this.startSetProductFilters(this.form);
  }
};
</script>

<style scoped lang="scss">
.categories--select {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid $peacock;
  border-radius: 5px;
  background-color: $frost;
}
.categories--list {
  padding: 10px;
  @include grid($cols: 1fr, $row-gap: 10px);
}
.categories--list li label {
  @include grid($cols: 20px 1fr, $col-gap: 15px, $align: center);
}
.checkbox-respa,
.radio-sort,
.checkbox-status,
.radio-featured {
  padding-left: 8px;
  @include grid($cols: 1fr, $row-gap: 3px);
  label {
    @include grid($cols: 20px 1fr, $col-gap: 15px, $align: center);
  }
}
.major--overflow {
  max-height: 150px;
}
@media (min-width: $md) {
  .btn__red {
    display: none;
  }
}
</style>