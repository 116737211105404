<template>
  <div>
    <div v-if="!products || products.length <= 0" class="flex__col">
      <p
        class="text__center text__md text__bold margin__lg"
      >No Products found for your search, here are some other products you might be interested in</p>
      <!-- <ul class="products--list">
        <Product
          v-for="product in getFeaturedProducts.slice(0,3)"
          :key="product._id"
          :product="product"
        />
      </ul>-->
    </div>
    <ul v-else class="products--list">
      <Product
        v-for="product in products"
        :key="product._id"
        :product="product"
        @resetPage="emitResetPage"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "./Product";
export default {
  props: ["products"],
  components: {
    Product
  },
  computed: {
    ...mapGetters(["getFeaturedProducts"])
  },
  methods: {
    emitResetPage() {
      this.$emit("resetPage");
    }
  }
};
</script>

<style scoped lang="scss">
.products--list {
  @include grid($cols: 1fr, $col-gap: 50px, $row-gap: 50px);
  height: fit-content;
}
@media (min-width: $sm) {
  .products--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $md) {
  .products--list {
    grid-template-columns: 1fr;
  }
}
@media (min-width: $lg) {
  .products--list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>