<template>
  <section class="products">
    <ProductAdmin
      @toggleCart="toggleCart"
      @toggleExiting="toggleExiting"
      :activeOrder="getActiveOrder"
    />
    <span ref="productstop"></span>
    <Header @toggleFilters="toggleFilters" @toggleExiting="toggleExiting" />
    <div class="container">
      <Pagination @changePage="changePage" :pages="pageCount" :current="firstSlice" />
      <FilterList :filters="getProductFilters" :categories="getCategories" class="small--list" />
    </div>
    <section class="container">
      <Cart v-if="cartToggled" @toggleCart="toggleCart" :activeOrder="getActiveOrder" />
      <div class="products--content margin__md">
        <transition name="filter-slide">
          <FiltersForm
            v-if="filtersToggled"
            @toggleFilters="toggleFilters"
            @resetPage="resetPage"
            class="small--filters"
            :categories="getCategories"
            :productFilters="getProductFilters"
          />
        </transition>
        <!-- <filters class="large--filters"> -->
        <FiltersForm
          class="large--filters"
          :categories="getCategories"
          @resetPage="resetPage"
          :productFilters="getProductFilters"
        />
        <!-- </filters> -->
        <Products class="products--items" :products="displayedProducts" @resetPage="resetPage" />
      </div>
      <div class="margin__md">
        <Pagination @changePage="changePage" :pages="pageCount" :current="firstSlice" />
      </div>
      <Export v-if="isAdmin" :products="getFilteredProducts" :user="getUserDetails" />
    </section>
    <exit v-if="exitToggled">
      <ExitOrder @toggleExiting="toggleExiting" />
    </exit>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import FilterList from "./FilterList";
import ProductAdmin from "./ProductAdmin";
import Cart from "./Cart";
import FiltersForm from "./FiltersForm";
import Products from "./Products";
import Pagination from "../../Pagination";
import Export from "./Export";
import ExitOrder from "./ExitOrder";
export default {
  components: {
    Header,
    FilterList,
    ProductAdmin,
    Cart,
    FiltersForm,
    Products,
    Pagination,
    Export,
    ExitOrder
  },
  metaInfo: {
    title: "View Products | WOW"
  },
  data() {
    return {
      filtersToggled: false,
      cartToggled: false,
      exitToggled: false,
      displayCount: 16,
      firstSlice: 0
    };
  },
  computed: {
    ...mapGetters([
      "getCategories",
      "getFilteredProducts",
      "getProductFilters",
      "getActiveOrder",
      "getUserDetails"
    ]),
    productCount() {
      return this.getFilteredProducts.length;
    },
    pageCount() {
      return Math.ceil(this.productCount / this.displayCount);
    },
    displayedProducts() {
      // pass these into products component
      return this.getFilteredProducts.slice(
        this.firstSlice * this.displayCount,
        this.firstSlice * this.displayCount + this.displayCount
      );
    },
    isAdmin() {
      return this.$route.fullPath.includes("/admin");
    }
  },
  methods: {
    toggleFilters() {
      this.filtersToggled = !this.filtersToggled;
    },
    toggleCart() {
      this.cartToggled = !this.cartToggled;
    },
    toggleExiting() {
      this.exitToggled = !this.exitToggled;
    },
    changePage(index) {
      if (index === 0) {
        this.firstSlice = 0;
      }
      if (index === 1) {
        if (this.firstSlice === 0) {
          return;
        }
        this.firstSlice -= 1;
      }
      if (index === 2) {
        if (this.firstSlice + 1 === this.pageCount) {
          return;
        }
        this.firstSlice += 1;
      }
      if (index === 3) {
        this.firstSlice = this.pageCount - 1;
      }
      this.addRouteParams();
      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs.productstop.scrollIntoView({
        block: "nearest",
        behavior: "smooth"
      });
    },
    addRouteParams() {
      let queryChar = this.$route.fullPath.includes("?") ? "&" : "?";
      if (this.$route.fullPath.includes("page=")) {
        this.$router.push(
          `${this.$route.fullPath.replace(
            /page=\d/,
            `${queryChar}page=${this.firstSlice + 1}`
          )}`
        );
      } else {
        this.$router.push(
          `${this.$route.fullPath}${queryChar}page=${this.firstSlice + 1}`
        );
      }
    },
    resetPage() {
      this.firstSlice = 0;
      if (this.$route.query.page) return this.$router.replace({ query: null });
    }
  },
  mounted() {
    if (this.$route.query.page) {
      this.firstSlice = this.$route.query.page - 1;
    }
  }
};
</script>

<style scoped lang="scss">
@media (min-width: $md) {
  /* .large--filters {
    top: 68px;
  } */
  .products--content {
    @include grid($cols: 1fr 3fr, $col-gap: 45px);
  }
}
</style>