<template>
  <section class="flex__right mb-15">
    <download-excel
      :data="productsData"
      name="product-data.csv"
      type="csv"
      class="mt-15 btn btn__sm btn__frost"
    >Export</download-excel>
  </section>
</template>

<script>
export default {
  props: ["products", "user"],
  data() {
    return {
      access: ["admin", "super-admin"]
    };
  },
  computed: {
    productsData() {
      if (!this.access.includes(this.user.permission)) return null;
      const copyData = JSON.parse(JSON.stringify(this.products));
      const products = copyData.map(product => {
        let custs = "";
        if (product.customizations && product.customizations.length > 0) {
          product.customizations.forEach(cust => {
            custs = `${custs}, ${cust.name}`;
          });
        }
        return {
          name: product.name,
          description: product.description,
          status: product.status,
          weight:
            product.info && product.info.weight ? product.info.weight : "na",
          dimensions:
            product.info && product.info.dimensions
              ? product.info.dimensions
              : "na",
          isLocal:
            product.info && product.info.isLocal ? product.info.isLocal : false,
          features:
            product.info && product.info.features
              ? product.info.features
              : "na",
          tags: product.info ? product.info.tags : "na",
          loCost: product.loCost,
          itemCost: product.itemCost,
          invoiceCost: product.invoiceCost,
          approxValue: product.approxValue,
          isInventoried: product.isInventoried,
          inventory: product.inventory ? product.inventory : "na",
          customizations: custs
        };
      });
      return products;
    }
  }
};
</script>