<template>
  <section class="products-header">
    <div class="container">
      <!-- <h1 class="text__center padding__md">{{ heading }}</h1> -->
      <div class="products-header__grid">
        <FilterButton
          class="products-header--sort"
          @toggleFilters="emitToggleFilters"
          @clearFilters="clearFilters"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import FilterButton from "../../FilterButton";
export default {
  components: {
    FilterButton
  },
  data() {
    return {};
  },
  computed: {
    route() {
      return this.$route.fullPath;
    },
    heading() {
      if (this.route.includes("/new-order")) {
        return "New Order: Products";
      } else if (this.route.includes("/past-order")) {
        return "Past Order: Products";
      } else {
        return "Products";
      }
    }
  },
  methods: {
    ...mapActions(["startUnsetProductFilters"]),
    emitToggleFilters() {
      this.$emit("toggleFilters");
    },
    clearFilters() {
      this.startUnsetProductFilters();
    },
    emitToggleExiting() {
      this.$emit("toggleExiting");
    }
  }
};
</script>

<style scoped lang="scss">
.products-header--sort {
  margin-bottom: 10px;
}
@media (min-width: $md) {
  .products-header--sort {
    display: none;
  }
}
</style>